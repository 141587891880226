<template>
  <main class="h-screen bg-black">
    <div class="w-full h-full flex flex-col items-center justify-center">
      <img src="@/assets/images/logo.png" style="max-width: 70%;" />
      <h1 class="text-2xl font-bold mb-6 text-white">
        Welcome to AnyContent
      </h1>
      <h1 class="text-xl font-bold mb-6 text-white">v{{ version }}</h1>
    </div>
  </main>
</template>

<script>
export default {
  name: "Home",
  data() {
    return {
      version: process.env.VUE_APP_VERSION,
    };
  },
};
</script>

<style></style>
